.image-container {
  position: fixed; /* Fixed position */
  top: 0;
  left: 0;
  width: 100%;
  height: 35vh; /* 1/3 of the viewport height */
  z-index: 0; /* Sit below the info sheet */
}

.detail-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info-sheet {
  position: relative;
  top: 28vh; 
  width: 100%;
  background: transparent !important;
  z-index: 1;
}

.float-top-left {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 2;
}